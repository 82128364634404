import { useTheme } from '@material-ui/core'
import React from 'react'
export interface IDotProps {
    active?: boolean
    color?: string
    onClick?: () => void
}

const Dot: React.FC<IDotProps> = ({
    active,
    color,
    onClick
}): React.ReactElement => {
    const { palette } = useTheme()
    return (
        <div
            onClick={onClick}
            style={{
                height: 8,
                width: 8,
                margin: 6,
                borderRadius: 4,
                backgroundColor: color || palette.primary.main,
                opacity: active ? 1 : 0.25,
                transition: 'all 0.5s ease'
            }}
        />
    )
}

export default Dot
