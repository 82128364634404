import { ArticleCard } from 'common/components/sharedLibrary'
import React from 'react'
import { Slider } from '../Slider'
import { LatestNewsProps } from './LatestNews'

export interface LatestNewsCardsMobileProps
    extends Pick<LatestNewsProps, 'list'> {}

export const LatestNewsCardsMobile: React.FC<LatestNewsCardsMobileProps> = ({
    list
}) => {
    return <Slider component={ArticleCard} items={list} />
}
