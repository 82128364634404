import { Box, Grid, Hidden } from '@material-ui/core'
import {
    CardCtaContained,
    CardCtaContainedProps
} from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface NoticeProps {
    title: string
    text: string
    subTitle: string
    callToAction: CardCtaContainedProps
    variant?: 'border' | 'noBorder'
}

export const Notice: React.FC<NoticeProps> = ({
    text,
    title,
    subTitle,
    callToAction,
    variant = 'border'
}) => {
    const desktop = useDesktop()

    const noticeBorder = {
        borderTop: desktop ? '1px solid rgba(193,199,225,0.5)' : 'none',
        borderBottom: desktop ? '1px solid rgba(193,199,225,0.5)' : 'none',
        paddingTop: desktop ? '72px' : '0px',
        paddingBottom: desktop ? '72px' : '0px'
    }

    return (
        <section>
            <Grid
                container
                data-testid='notices'
                style={variant === 'border' ? noticeBorder : undefined}
            >
                <Grid item xs={12} md={6}>
                    {desktop && (
                        <Text
                            box={{ mb: 6, color: '#4DB7AB' }}
                            label={title}
                            variant='overline'
                            component='h1'
                        />
                    )}
                    <Text box={{ mb: 6 }} html label={text} variant='h5' />
                    {subTitle && (
                        <Text
                            box={{ mb: 9 }}
                            html
                            label={subTitle}
                            style={{ color: '#766F90', fontSize: '20px' }}
                        />
                    )}
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={3} />
                </Hidden>
                <Grid item xs={12} md={3}>
                    <Box mt={desktop ? 19 : 0}>
                        {callToAction && <CardCtaContained {...callToAction} />}
                    </Box>
                </Grid>
            </Grid>
            {!desktop && <Spacer variant='md'></Spacer>}
        </section>
    )
}
