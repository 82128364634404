import { Box, Grid } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import { ArticleCard } from 'common/components/sharedLibrary'
import React from 'react'
import { LatestNewsProps } from './LatestNews'

export interface LatestNewsCardsDesktopProps
    extends Pick<LatestNewsProps, 'list'> {}

export const LatestNewsCardsDesktop: React.FC<LatestNewsCardsDesktopProps> = ({
    list
}) => {
    return (
        <Box data-testid='LatestNewsCardsDesktop' mb={9}>
            <Grid container spacing={6}>
                {list.map((post, index) => (
                    <Grid key={index} item xs={12} md={4}>
                        <Link path={`/blog/${post.url}`}>
                            <ArticleCard {...post} />
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
