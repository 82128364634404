import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box } from '@material-ui/core'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { ArticleCardProps } from 'common/components/sharedLibrary'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { LatestNewsButton } from './LatestNewsButton'
import { LatestNewsCardsDesktop } from './LatestNewsCardsDesktop'
import { LatestNewsCardsMobile } from './LatestNewsCardsMobile'

export interface LatestNewsProps {
    label?: string
    list: ArticleCardProps[]
}

export const LatestNews: React.FC<LatestNewsProps> = props => {
    const desktop = useDesktop()
    return (
        <aside data-testid='LatestNews'>
            <Wrapper>
                <Box
                    alignItems={'center'}
                    justifyContent={desktop ? 'center' : undefined}
                    display='flex'
                    mb={8}
                    width='100%'
                >
                    <Text variant='title-md'>
                        {props.label ?? 'Latest News'}
                    </Text>
                    <Box flex={1} />
                    <LatestNewsButton variant='md' />
                </Box>
            </Wrapper>
            <Wrapper>
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems={desktop ? 'center' : undefined}
                >
                    {desktop ? (
                        <LatestNewsCardsDesktop {...props} />
                    ) : (
                        <LatestNewsCardsMobile {...props} />
                    )}
                </Box>
            </Wrapper>
        </aside>
    )
}
