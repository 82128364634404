import React from 'react'
import Dot from './Dot'

export interface IDotsProps {
    color?: string
    count: number
    value: number
    onClick?: (index: number) => void
}

export const Dots: React.FC<IDotsProps> = ({
    color,
    count,
    onClick,
    value
}): React.ReactElement | null => {
    if (count < 2) return null
    const dotArray = [...Array(count)]
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 32,
                width: 'calc(100% - 16px)'
            }}
        >
            <div
                style={{
                    display: 'flex'
                }}
            >
                {dotArray.map((dot, index) => (
                    <Dot
                        active={index === value}
                        color={color}
                        key={index}
                        onClick={() => onClick && onClick(index)}
                    />
                ))}
            </div>
        </div>
    )
}
