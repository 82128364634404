import { Box, Grid, Hidden } from '@material-ui/core'
import { Box as CUIBox } from '@chakra-ui/react';
import {
    CardCtaContained,
    CardCtaContainedProps
} from 'common/components/molecules/CardCta'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text';

export interface NoticeCompactProps {
    text: string
    subTitle: string
    callToAction: CardCtaContainedProps
}

export const NoticeCompact: React.FC<NoticeCompactProps> = ({
    text,
    subTitle,
    callToAction
}) => {
    const desktop = useDesktop()
    return (
        <section>
            <CUIBox borderY={'1px solid rgba(193,199,225,0.5)'} pt={'25px'} pb={'27px'}>
                <Grid
                    container
                    data-testid='notices'
                >
                    <Grid item xs={12} md={6}>
                        <Text 
                            sx={{ mb: 6 }} 
                            color='brand.600' 
                            variant='subtitle-md'>
                                {text}
                            </Text>
                        {subTitle && (
                            <Text
                                sx={{ mb: 9 }}
                                variant='body-sm'
                                color='monochrome.800'>
                                    {subTitle}
                            </Text>
                        )}
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} md={3} />
                    </Hidden>
                    <Grid item xs={12} md={3}>
                        <Box display='flex' justifyContent='right' alignItems='right' mt={desktop ? 19 : 0}>
                            {callToAction && <CardCtaContained {...callToAction} />}
                        </Box>
                    </Grid>
                </Grid>
            </CUIBox>
         </section>
    )
}
