import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

export interface LeftIntroProps {
    heading: string
    text: string
    title: string
}

export const LeftIntro: React.FC<LeftIntroProps> = ({
    heading,
    text,
    title
}) => {
    const desktop = useDesktop()

    return (
        <Box
            position='sticky'
            top={144}
            marginBottom={desktop ? 12 : 0}
            alignSelf='flex-start'
        >
            <Text
                box={{ mb: 6, color: '#4DB7AB' }}
                label={heading}
                variant='overline'
                component='p'
            />
            <Text
                box={{ mb: desktop ? 6 : 0 }}
                label={title}
                variant={desktop ? 'h4' : 'h2'}
                component='h1'
            />
            {text && (
                <Text
                    box={{ mb: desktop ? 6 : 0 }}
                    color='textSecondary'
                    html
                    label={text}
                    style={{
                        fontWeight: 500,
                        fontSize: 18,
                        lineHeight: 24 / 18
                    }}
                    component='p'
                />
            )}
        </Box>
    )
}
