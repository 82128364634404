import { Box } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import { AccordionItem, AccordionItemProps } from './AccordionItem'

export interface StepProps {
    step_number: string
    text: string
    title: string
    accordion?: AccordionItemProps[]
}

export const Step: React.FC<StepProps> = ({
    accordion = [],
    step_number,
    title,
    text
}) => {
    return (
        <Box style={{ width: '100%' }}>
            <Text
                label={step_number}
                variant='subtitle1'
                color='textSecondary'
                style={{
                    marginBottom: '20px'
                }}
                component='p'
            />
            <Text
                label={title}
                variant='h6'
                style={{
                    marginBottom: '20px',
                    color: '#3A3160'
                }}
                component='h2'
            />
            <Text
                label={text}
                variant='body1'
                style={{
                    marginBottom: '50px',
                    color: '#8983A0'
                }}
                html
            />
            {accordion.map(accordionItem => (
                <AccordionItem
                    key={accordionItem.title}
                    text={accordionItem.text}
                    title={accordionItem.title}
                ></AccordionItem>
            ))}
        </Box>
    )
}
