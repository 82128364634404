import { Slider as ReactScrollSlider } from '@lifarl/react-scroll-snap-slider'
import { Box } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import React, { useState } from 'react'
import { Dots } from './Dots'

export interface SliderProps {
    component: any
    dotColor?: string
    items: any[]
}

export const Slider: React.FC<SliderProps> = ({
    dotColor,
    items,
    component: Component
}) => {
    const [index, setIndex] = useState(0)
    return (
        <>
            <ReactScrollSlider
                onSlideVisible={(index: number) => setIndex(index)}
            >
                {items.map((item, index) => (
                    <Box key={index} width='calc(100% - 48px)'>
                        <Link path={`/blog/${item.url}`}>
                            <Component {...item} />
                        </Link>
                    </Box>
                ))}
            </ReactScrollSlider>
            <Dots color={dotColor} count={3} value={index} />
        </>
    )
}
