import { Box, Grid, Hidden } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TimelineContent } from '@material-ui/lab'
import Timeline from '@material-ui/lab/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import { Spacer } from 'common/components/molecules/Spacer'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'
import { LeftIntro } from '../LeftIntro'
import { Step, StepProps } from './Step'

export interface TimelineStepsProps {
    title: string
    text: string
    heading: string
    steps: StepProps[]
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        accordion: {
            borderRadius: '0px !important',
            boxShadow:
                '0px 2px 1px -1px #F7F8FB, 0px 1px 1px 0px #F7F8FB, 0px 1px 3px 0px #F7F8FB'
        },
        heading: {
            color: '#5261AC'
        },
        body: {
            color: '#8983A0',
            paddingTop: '20px'
        },
        oppositeContent: {
            flex: 15,
            paddingTop: '0px'
        },
        squareIcon: {
            borderRadius: 0,
            boxShadow: 'none',
            background: '#50C9B5'
        },
        timeline_seperator: {
            backgroundColor: '#EBEDF7'
        },
        li: {
            listStyleImage: "url('smiley.gif')"
        }
    })
)
export const TimelineSteps: React.FC<TimelineStepsProps> = ({
    text,
    title,
    heading,
    steps = []
}) => {
    const classes = useStyles()
    const desktop = useDesktop()
    return (
        <Box
            mx={desktop ? 6 : 0}
            my={desktop ? 6 : 0}
            display='flex'
            flexDirection='column'
            component='section'
        >
            <Spacer variant={desktop ? 'lg' : 'md'} />
            <Grid container>
                <Grid item xs={12} md={3}>
                    <LeftIntro text={text} title={title} heading={heading} />
                </Grid>
                <Hidden smDown>
                    <Grid item xs={1} md={1} />
                </Hidden>
                <Grid item xs={12} md={8}>
                    <Timeline>
                        {steps.map((step, index) => (
                            <TimelineItem key={step.step_number}>
                                <TimelineSeparator>
                                    <TimelineDot
                                        className={classes.squareIcon}
                                    />
                                    {index === steps.length - 1 ? (
                                        <TimelineConnector
                                            className={
                                                classes.timeline_seperator
                                            }
                                            style={{ display: 'none' }}
                                        ></TimelineConnector>
                                    ) : (
                                        <TimelineConnector
                                            className={
                                                classes.timeline_seperator
                                            }
                                        ></TimelineConnector>
                                    )}
                                </TimelineSeparator>
                                <TimelineContent
                                    className={classes.oppositeContent}
                                >
                                    <Step {...step} />
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </Grid>
            </Grid>
            <Spacer />
        </Box>
    )
}
