import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Text } from 'common/components/molecules/Text'
import React from 'react'

export interface AccordionItemProps {
    text: string
    title: string
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            color: '#5261AC',
            padding: '10px 0px'
        },
        body: {
            color: '#8983A0',
            paddingTop: '20px'
        },
        accordion: {
            borderRadius: '0px !important',
            boxShadow:
                '0px 2px 1px -1px #F7F8FB, 0px 1px 1px 0px #F7F8FB, 0px 1px 3px 0px #F7F8FB',
            background: 'none'
        }
    })
)
export const AccordionItem: React.FC<AccordionItemProps> = ({
    title,
    text
}) => {
    const classes = useStyles()
    const firstTabCheck =
        title === 'Invest some of it' ? (
            <Accordion className={classes.accordion} defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    style={{
                        borderBottom: '2px solid #EBEDF7'
                    }}
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Text label={text} className={classes.body} html>
                        {text}
                    </Text>
                </AccordionDetails>
            </Accordion>
        ) : (
            <Accordion className={classes.accordion} defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    style={{
                        borderBottom: '2px solid #EBEDF7'
                    }}
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Text label={text} className={classes.body} html>
                        {text}
                    </Text>
                </AccordionDetails>
            </Accordion>
        )
    return firstTabCheck
}
