import { Button } from '@material-ui/core'
import { Link } from 'common/components/atoms/Link'
import React from 'react'

export interface LatestNewsButtonProps {
    variant?: 'lg' | 'md'
}

export const LatestNewsButton: React.FC<LatestNewsButtonProps> = ({
    variant = 'lg'
}) => {
    return (
        <Link path='/blog'>
            <Button
                variant='contained'
                style={{
                    color: '#5261AC',
                    background: '#EBEDF7',
                    borderRadius: 36,
                    fontWeight: 600,
                    fontSize: variant === 'lg' ? 16 : 14,
                    height: variant === 'lg' ? 37 : 31,
                    lineHeight: 30 / 16,
                    padding: variant === 'lg' ? '9px 28px' : '7px 18px',
                    textTransform: 'unset',
                    whiteSpace: 'nowrap',
                    width: variant === 'lg' ? 112 : 85
                }}
            >
                View all
            </Button>
        </Link>
    )
}
